const moment = window.moment;

moment.locale('zh-cn');

// 获取随机数
export const getRandom = ({ min = 0, max = 10 }) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// 比较数组中的值，找出最大数值
export const getMaxInArray = (array) => {
  if (!array || !Array.isArray(array)) return null;
  // 或者：Math.max(...array);
  return array.reduce((max, set) => {
    const _set = parseFloat(set) || 0;
    return _set >= max ? _set : max;
  }, 0);
};

// 获取某个数值的接近的整十、整百、整千等数值
export const numAlign = (num) => {
  if (!num) return 0;
  let _round = Math.ceil(parseInt(num.toString().split(".")[0]) / 2);
  _round = +_round
    .toString()
    .split("")
    .map((set, index) => (index === 0 ? 1 : 0))
    .join("");
  return Math.ceil(num / _round) * _round;
};

// 深拷贝
export const deepClone = (obj) => {
  var objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = deepClone(obj[key]);
        } else {
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
};

// 车牌号字符串脱敏
export const concealCarPlateNumb = (str) => {
  if (!str?.trim()) return '';
  const numBegin = str.substring(0, 2);
  const numEnd = str.substring(str.length - 2);
  return numBegin + '***' + numEnd;
}

// 身份证字符串脱敏
export const concealIdCardNumb = (str) => {
  var pat = /(\d{11})\d*(\d{4})/
  var b = (str || '').replace(pat, '$1****$2')
  return b
}

// 手机号字符串脱敏
export const concealPhoneNumb = (str) => {
  var pat = /(\d{3})\d*(\d{4})/
  var b = (str || '').replace(pat, '$1****$2')
  return b
}

// 是否车牌（含新能源车牌）
export const isVehicleNumber = (val = '') => {
  var xreg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/;
  var creg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/;
  if (val.length == 7) {
    return creg.test(val);
  } else if (val.length == 8) {
    return xreg.test(val);
  } else {
    return false;
  }
};

// 是否为身份证号
export const isCardId = val => {
  const re = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
  return re.test(val);
};

// 是否手机号
export const isMobile = val => {
  return /^1[3456789][0-9]{9}$/.test(val);
};

/**
 * 日期格式化
 * @description 基于 moment 的日期格式化
 * @param {Number|String|Date} value
 * @param {String} format 更多配置参考 moment 文档 <https://momentjs.com/docs/>
 * @param {String} unlimitedText
 * @returns String
 */
export const dateFormat = (value, format = 'YYYY-MM-DD HH:mm:ss', unlimitedText = '') => {
  if (!value) return '';
  value = 'string' === typeof value ? value.replace(/-/g, '/') : value;
  const date = new Date(value);
  if (Number.isNaN(date.getTime())) return '';
  if (9999 === date.getFullYear()) return unlimitedText;
  return moment(date).format(format);
};

/**
 * 千分位数字格式化
 * @param {Number|String} value
 * @param {int} fixed 修正位数
 * @returns {null|String}
 */
export const amount = (value, fixed = 2) => {
  if (isNaN(value)) return null;
  if ([null, undefined, ''].includes(value)) return null;
  if (0 === value) return parseFloat(0).toFixed(fixed);
  let num = value.toString().replace(/,/g, '');
  num = parseFloat(num || 0);
  num = num.toFixed(+fixed || 0).toString();
  num = num.split('.');
  if (1 === num.length) return num[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  return [num[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'), num[1]].join('.');
};

/**
 * 单元格金额处理
 * @param {Number|String} value
 * @param {int} placeholder 值为空时的占位符
 * @param {int} fixed 修正位数
 * @returns String
 */
export const columnAmount = (
  value,
  { placeholder = '-', fixed = 2, prefix = '', suffix = '' } = {}
) => {
  if (0 === value) return 0;
  if (isNaN(value)) return placeholder;
  const _amount = amount(value, fixed);
  return null === _amount ? placeholder : prefix + _amount + suffix;
};

// 去除左右空格
export const trimValue = (value) => {
  const _trim = (val) => val.trimLeft().trimRight();

  // 字符串直接过滤
  if ('string' === typeof value) return _trim(value);

  // 对象递归过滤
  if ('[object Object]' === Object.prototype.toString.call(value)) {
    for (const key in value) {
      const _value = value[key];
      if ('string' === typeof _value) {
        value[key] = trimValue(_value);
      }
    }
    return value;
  }

  // 数组
  if (Array.isArray(value)) {
    value = [...value].map((set) => trimValue(set));
  }

  // 其他不予处理
  return value;
};

// 去除空值参数，包含空字符串、空数组、空对象
// value 仅支持 对象 和 数组 类型
export const removeEmpty = (value) => {
  const _empty = (data) => {
    if ('' === data) return true;
    if (Array.isArray(data) && !data.length) return true;
    if ('{}' === JSON.stringify(data)) return true;
    return false;
  };

  if (value instanceof Object) {
    for (const key in value) {
      if (_empty(value[key])) {
        delete value[key];
      }
    }
    return value;
  } else if (Array.isArray(value)) {
    // 数组处理
    return [...value].filter((set) => !_empty(set));
  }
  return value;
};
