import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: '404',
    meta: {
      title: '还款管理平台',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/404/Index.vue'),
  },

  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/login/Index.vue'),
  },

  {
    path: '/index',
    name: 'index',
    meta: {
      title: '还款管理平台',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/index/Index.vue'),
  },

  {
    path: '/prepayCalc',
    name: 'prepayCalc',
    meta: {
      title: '还款管理平台',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/prepayCalc/Index.vue'),
  },

  {
    path: '/brokerData',
    name: 'brokerData',
    meta: {
      title: '还款数据',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/broker_data/Index.vue'),
  },

  {
    path: '/wechatOfficialAccountData',
    name: 'wechatOfficialAccountData',
    meta: {
      title: '公众号数据',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/wechat_official_account_data/Index.vue'),
  },

  {
    path: '/prepareData',
    name: 'prepareData',
    meta: {
      title: '进件数据',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/prepare_data/Index.vue'),
  },

  {
    path: '/bankCard',
    name: 'bankCard',
    meta: {
      title: '换卡绑定',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/bankCard/index.vue'),
  },

  {
    path: '/contract',
    name: 'contract',
    meta: {
      title: '合同查看',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/contract/index.vue'),
  },


{
  path: '/home/lifeServices/externalLink',
  name: 'externalLink',

  children: [
    {
      path: '/api/repayBank/contract/dewu/pay/protocol',
      meta: {
        link: 'http://192.168.1.196:8080/',
        title: '代扣协议'
      }
    },
],
component: () => import(/* webpackChunkName: "router" */ '@/components/LinkHome.vue'),
},


  // {
  //   path: '/navHeader',
  //   name: 'navHeader',
  //   meta: {
  //     title: '换卡绑定',
  //   },
  //   component: () => import(/* webpackChunkName: "router" */ '@/views/bankCard/index.vue'),
  // },


  //
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  to.meta && to.meta.title && (document.title = to.meta.title);
  if (to.meta && to.meta.anonymous) {
    return next()
  }
  next()
});

export default router;
